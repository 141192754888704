import React, { useState } from 'react'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { socialData } from './SocialData'
import { SideBarData } from './SideBarData'
import '../../styles/Navigator.css'

import logo from '../../assets/logo.png'

function Navbar () {
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)

  const scroll = (path) => {
    const section = document.querySelector(`${path}`)
    section.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (

    <>
        <div className="navbar">
          <div className='logo'>
            <img src={logo} alt=""/>
          </div>
          <Link to = "#" className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar}/>
          </Link>
        </div>

        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className='nav-menu-items' onClick={showSidebar}>
          <li className="navbar-toggle">
                  <Link to="#" className = "menu-bars">
                    <AiIcons.AiOutlineClose/>
                  </Link>
          </li>

          {SideBarData.map((item, index) => {
            return (
              <li key={index} className = {item.cName} onClick={() => scroll(item.path)}>
                  <button>
                  {item.icon}
                  <span> {item.title} </span>
                  </button>
              </li>
            )
          })}
          <li className='navbar-toggle'>
          {socialData.map((social) => {
            return (
              <a href={social.link} key={social.id}>
                <img src={social.platform} alt={social.alt} />
              </a>
            )
          })}
          </li>
          </ul>
        </nav>

    </>

  )
}

export default Navbar
