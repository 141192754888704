export const Data = [
  {
    question: 'What is Metacrew?',
    answer: 'In addition to being a company, Metacrew is a contributor to the WeedCoin. The mission is to raise its value through the creation and intertwinement of different projects where transactions will happen to be in WeedCoins.'
  },
  {
    question: 'Why would I like to be a Project 420 Community member?',
    answer: 'We are creating the biggest cannabis community in the Crypto industry! Moreover, every community member will receive unique perks in every single project that Metacrew will create in the future.'
  },
  {
    question: 'Why would I like to earn WeedCoins?',
    answer: 'Community members earn WeedCoins just by playing the video game. The main utility of this token will be to make payments in our future projects such as the Metaverse and our cannabis product-related brand, where only community members will be able to make payments with the coins that they have earned in the P2E game, in exchange for highly valuable products.'
  }
]
