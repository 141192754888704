import React from 'react'
import '../styles/About.css'
import aboutNFTs from '../assets/about.png'
export default function About () {
  return (
    <div className='about' id='about'>

    <div className='title'>
          <h3>Another Reason To </h3>
          <h1>Join Project 420</h1>
      </div>

    <div className="container-welcome">
      <div className='image-child'>
       <img src={aboutNFTs} alt="welcome NFT" />
      </div>

      <div className='text-content-about'>
        <p>
        Project 420 is your ticket to receive the most valuable perks that any community has ever given before! This NFT P2E video game is just the beginning of the WeedCoin. The token that will be used to generate a sustainable economy through the products and services generated throughout our different projects.
        </p>
        <button className='rounded-full shadow-md bg-yellow-500 py-3 px-10 uppercase tracking-wider font-bold cursor-pointer' type="button"> <a href="https://discord.gg/s4w7hcqY8H">JOIN US!</a></button>
      </div>
    </div>
    </div>
  )
}
