import React from 'react'
import '../styles/Plant.css'
import plantSoon from '../assets/plant-videogame.png'
export default function Plant () {
  return (
        <div className='plant-wrapper' id='plant'>
            <div className="header">
              <h1>Start Planting Now</h1>
            </div>
            <div>
              <img src={plantSoon} alt="NFT VideoGame Launcher" />
            </div>
              <button className='rounded-full shadow-md bg-yellow-500 py-5 px-10 uppercase tracking-wider font-bold cursor-pointer' type="button">COMING SOON</button>
        </div>
  )
}
