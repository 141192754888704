import React, { useState } from 'react'
import { Data } from './FaqData'
import '../../styles/Faqs.css'
import { IconContext } from 'react-icons'
import { FiPlus, FiMinus } from 'react-icons/fi'

const Accordion = () => {
  const [clicked, setClicked] = useState(false)

  const toggle = index => {
    clicked === index
      ? setClicked(null)
      : setClicked(index)
  }

  return (
    <IconContext.Provider value={{ color: '#00FFB9', size: '25px' }}>
      <div className="AccordionSection" id='faqs'>
        <div className="Container">
          {Data.map((item, index) => {
            return (
              <>
                <div className="Wrap" onClick={() => toggle(index)} key={index}>
                  <h1>{item.question}</h1>
                  <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                </div>
                {clicked === index
                  ? (
                    <div className="Dropdown">
                      <p>{item.answer}</p>
                    </div>
                    )
                  : null}
              </>
            )
          })}
        </div>
      </div>
    </IconContext.Provider>
  )
}

export default Accordion
