import React from 'react'
import Accordion from '../components/Faq/Accordion'
import smoker from '../assets/smoker.png'
function Faqs () {
  return (
    <div className='faq-container' id='faqs'>
      <div className="faq-header">
        <div> <h1>FAQs</h1></div>
        <div className="image-container"><img src={smoker} alt="smoker" /></div>
      </div>

      <Accordion/>
    </div>
  )
}

export default Faqs
