import React from 'react'
import PropTypes from 'prop-types'

function ImageDisplay ({ property, image }) {
  return (
    <>
      <h3>{property}</h3>
      <img src={image} alt="NFT Gallery" />
    </>
  )
}

export default ImageDisplay

ImageDisplay.propTypes = {
  property: PropTypes.string,
  image: PropTypes.string
}
