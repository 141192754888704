import React from 'react'
import Gallery from '../components/Gallery/Gallery'
import '../styles/Samples.css'

export default function Plant () {
  return (
    <div className='samples' id='gallery'>
      <div className="header">
        <h1> Meet The Crew </h1>
      </div>
      <Gallery/>
    </div>
  )
}
