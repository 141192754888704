import React from 'react'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'
import * as GiIcons from 'react-icons/gi'
import * as RiIcons from 'react-icons/ri'

export const SideBarData = [
  {
    title: 'Welcome',
    path: '#welcome',
    icon: <AiIcons.AiOutlineHome/>,
    cName: 'nav-text'
  },

  {
    title: 'About',
    path: '#about',
    icon: <IoIcons.IoIosPaper/>,
    cName: 'nav-text'
  },

  {
    title: 'Gallery',
    path: '#gallery',
    icon: <RiIcons.RiGalleryLine/>,
    cName: 'nav-text'
  },
  {
    title: 'Plant',
    path: '#plant',
    icon: <GiIcons.GiConsoleController/>,
    cName: 'nav-text'
  },
  {
    title: 'FAQs',
    path: '#faqs',
    icon: <FaIcons.FaQuestion/>,
    cName: 'nav-text'
  }
]
