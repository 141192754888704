import React from 'react'
import '../styles/Navigator.css'
import Navbar from '../components/Nav/Navbar'

export default function Navigator () {
  return (
        <div className='NavigatorWrapper'>

          <Navbar/>
        </div>
  )
}
