import React, { useState, useEffect } from 'react'
import { imageGalleryData } from './IGData.js'
import ImageDisplay from './ImageDisplay'
import '../../styles/Samples.css'

function Gallery () {
  const [displayedImage, setdisplayedImage] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      displayedImage < imageGalleryData.length - 1
        ? setdisplayedImage(displayedImage + 1)
        : setdisplayedImage(0)
    }, 1500)
  })

  return (
    <div className='gallery-container'>
      <ImageDisplay property={imageGalleryData[displayedImage].property} image = {imageGalleryData[displayedImage].image}/>
      </div>
  )
}

export default Gallery
