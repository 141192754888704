import twitter from '../../assets/twitter.png'
import tiktok from '../../assets/tiktok.png'
import discord from '../../assets/discord.png'

export const socialData = [
  {
    platform: twitter,
    link: 'https://twitter.com/PROJECT420NFT',
    alt: 'Twitter Access Link',
    id: 0
  },
  {
    platform: tiktok,
    link: 'https://www.tiktok.com/@project420nft',
    alt: 'Tik Tok Access Link',
    id: 1
  },
  {
    platform: discord,
    link: 'https://discord.gg/s4w7hcqY8H',
    alt: 'Discord Access Link',
    id: 2
  }
]
