import common from '../../assets/common.png'
import rare from '../../assets/rare.png'
import superrare from '../../assets/superrare.png'

export const imageGalleryData = [
  {
    image: common,
    property: 'common'
  },
  {
    image: rare,
    property: 'rare'
  },
  {
    image: superrare,
    property: 'super rare'
  }
]
