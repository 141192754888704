import React from 'react'
import '../styles/Home.css'
import title from '../assets/title.png'
import landing from '../assets/landing.png'

export default function Home () {
  return (
        <div className='HomeWrapper'>
            <div className='titlewrapper'>
                <img src={title} alt="Project 420" />
            </div>
            <div className='landingwrapper'>
              <img src={landing} alt="Project 420 Landing NFTs" />

            <div className='flex items-center justify-center'>
              <button className='rounded-full shadow-md bg-yellow-500 py-5 px-10 uppercase tracking-wider font-bold cursor-pointer' type="button">COMING SOON</button>
            </div>
            </div>

        </div>
  )
}
