import React from 'react'
import '../styles/Foot.css'
import logo from '../assets/logo.png'
import { socialData } from '../components/Nav/SocialData'

export default function Foot () {
  return (
        <div className='footer'>
          <div className="left-footer">
            <h2>FOLLOW US</h2>
            <div className="social-icons">
              {
                socialData.map((social) => {
                  return (
                  <a href={social.link} key={social.id} target="_blank" rel="noreferrer">
                    <img src={social.platform} alt={social.alt} />
                  </a>
                  )
                })
              }
            </div>
          </div>

          <div className="right-footer">
            <img src={logo} alt="Project 420 Logo" />
          </div>
        </div>
  )
}
