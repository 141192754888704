import React from 'react'
import '../styles/Welcome.css'
import welcome from '../assets/welcome-nft.png'

export default function Welcome () {
  return (
    <div className='welcome' id='welcome'>
     <div className="title">
          <h3>Welcome To </h3>
          <h1>Project 420</h1>
        </div>
        <div className="container-welcome">
            <div className='text-content'>
              <p>
              Project 420 is just the beginning of the Metacrew revolution. It is a P2E game of 10 000 NFTs whose mission consists on guarranteeing community members to be part of the most exclusive, valuable and unique community ever seen in the Crypto/ NFT industry.
              </p>
              <p>
               Our mission is to highly benefit our community members through the intertwinement of the different projects that Metacrew will create in the future.
              </p>
              <button className='rounded-full shadow-md bg-yellow-500 py-3 px-10 uppercase tracking-wider font-bold cursor-pointer' type="button"><a href="https://twitter.com/PROJECT420NFT">JOIN US!</a></button>
            </div>
            <div className='welcome-image'>
              <img src={welcome} alt="welcome NFT" />
            </div>
        </div>
    </div>

  )
}
