import React from 'react'
import Navigator from './views/Navigator'
import Home from './views/Home'
import Welcome from './views/Welcome'
import About from './views/About'
import Samples from './views/Samples'
import Plant from './views/Plant'
import Faqs from './views/Faqs'
import Foot from './views/Foot'

function App () {
  return (
    <div className="App">
      <Navigator/>
      <Home/>
      <Welcome/>
      <About/>
      <Samples/>
      <Plant/>
      <Faqs/>
      <Foot/>
    </div>
  )
}

export default App
